.table-style-1 {
  table {
    border-collapse: inherit;
    border-radius: 0.8rem;
    vertical-align: middle;
    thead {
      border: none;
      tr {
        th {
          color: $dark-gray;
          font-family: $primary-font-semibold;
          font-size: 1.8rem;
          background-color: rgba($light-green, 0.5);
          padding: 15px 25px;
          border-width: 0.1rem;
          border-color: $light-gray !important;
          & + th {
            border-left: 0;
          }
        }
        &:first-child {
          th {
            &:first-child {
              border-top-left-radius: 0.8rem;
            }

            &:last-child {
              border-top-right-radius: 0.8rem;
            }
          }
        }
        &.sub-heading {
          th {
            padding: 10px 25px;
            color: $medium-gray;
            font-family: $primary-font-semibold;
            font-size: 1.6rem;
            white-space: nowrap;
            vertical-align: middle;
            line-height: 1.2;
          }
        }
        & + tr {
          th {
            border-top: 0;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          color: $dark-gray;
          font-size: 1.6rem;
          font-family: $primary-font-regular;
          padding: 2rem 2.5rem;
          border: 1px solid $light-gray;
          text-align: center;
          & + td {
            border-left: 0;
          }
          &:first-child {
            text-align: left;
          }

          span {
            font-family: $primary-font-semibold;
          }

          .shortlisted-position{
            i{
              font-size: 2.2rem;
              color: $secondary-warning-color;
            }
            small{
              font-size: 1.2rem;
              color: $dark-gray;
              font-style: italic;
            }
          }
          &.status {
            color: $primary;
            font-size: 1.4rem;
            font-family: $primary-font-medium;
          }

          &:last-child {
            padding: 1.4rem;
            white-space: nowrap;
          }

          &.td-success {
            border-left: 0.8rem solid $border-left-success;
          }
          &.td-warning {
            border-left: 0.8rem solid $border-left-warning;
          }
          &.td-danger {
            border-left: 0.8rem solid $border-left-danger;
          }
        }

        &:last-child {
          td {
            &:first-child {
              border-bottom-left-radius: 0.8rem;
            }

            &:last-child {
              border-bottom-right-radius: 0.8rem;
            }
          }
        }
        &:first-child,
        & + tr {
          td {
            border-top: 0;
          }
        }
      }
    }
  }

  .btn-with-icon {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    min-width: inherit;
    font-size: 1.4rem;
    // .icon-paswrd-visible {
    //   font-size: 2rem;
    // }
    // .icon-tick{
    //   font-size: 2.4rem;
    // }
    // .icon-add{
    //   font-size: 2.4rem;
    // }
    // .icon-arrow-up {
    //   font-size: 1.8rem;
    // }
    // .icon-upload{
    //   font-size: 2.4rem;
    // }
    // .icon-left-hand{
    //   font-size: 2rem;
    // }
  }
  .btn-with-same-size {
    min-width: 12rem;
  }
}

.table-style-2 table thead tr.sub-heading th {
  white-space: normal;
}
.modal {
  .table-style-1 {
    table {
      thead {
        tr {
          th {
            font-size: 1.6rem;
            padding: 1.2rem 2.5rem;
          }
          &.sub-heading {
            th {
              padding: 1rem 2.5rem;
              font-size: 1.4rem;
            }
          }
        }
      }

      tbody {
        tr {
          td {
            font-size: 1.2rem;
            font-family: $primary-font-semibold;
            padding: 1rem 2.5rem;
          }
        }
      }
    }

    // .btn-with-icon {
    //   font-size: 1.2rem;
    //   gap: 0.4rem;
    //   .icon-paswrd-visible {
    //     font-size: 1.2rem;
    //   }
    //   .icon-arrow-up {
    //     font-size: 1.2rem;
    //   }
    //   .icon-add{
    //     font-size: 1.6rem;
    //   }
    // }
  }
}

// table .icon-app-received,
// .icon-send-call-letters,
// .icon-right-arrow {
//   font-size: 1.8rem;
// }
.nodal-officers-table{
  &.interviewing-candidates-table{
    table{
      thead{
        .sub-heading{
          th{
            
            white-space: normal !important;
            &:nth-child(1){
              width: 20% !important;
            }
            &:nth-child(2), &:nth-child(3){
              width: 20% !important;
            }
            &:last-child{
              width: 50% !important;
            }
          }
        }
      }
      tbody{
        background: $white-color;
      }
    }
    // .form-check{
    //   width: 50%;;
    // }
  }
}