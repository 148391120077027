@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ivdnc3');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?ivdnc3#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?ivdnc3') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?ivdnc3') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?ivdnc3##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-left-hand {
  &:before {
    content: $icon-left-hand; 
  }
}
.icon-clock {
  &:before {
    content: $icon-clock; 
  }
}
.icon-important {
  &:before {
    content: $icon-important; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
    color: $primary;
  }
}
.icon-re-distribute {
  &:before {
    content: $icon-re-distribute; 
  }
}
.icon-send-call-letters {
  &:before {
    content: $icon-send-call-letters; 
  }
}
.icon-forward-to-review {
  &:before {
    content: $icon-forward-to-review; 
  }
}
.icon-view-application {
  &:before {
    content: $icon-view-application; 
  }
}
.icon-view-call-letters {
  &:before {
    content: $icon-view-call-letters; 
  }
}
.icon-bullet {
  &:before {
    content: $icon-bullet; 
  }
}
.icon-left-alignment {
  &:before {
    content: $icon-left-alignment; 
  }
}
.icon-right-alignment {
  &:before {
    content: $icon-right-alignment; 
  }
}
.icon-reply {
  &:before {
    content: $icon-reply; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-bold {
  &:before {
    content: $icon-bold; 
  }
}
.icon-italics {
  &:before {
    content: $icon-italics; 
  }
}
.icon-underline {
  &:before {
    content: $icon-underline; 
  }
}
.icon-dashboard {
  &:before {
    content: $icon-dashboard; 
  }
}
.icon-master {
  &:before {
    content: $icon-master; 
  }
}
.icon-reports {
  &:before {
    content: $icon-reports; 
  }
}
.icon-post-bank {
  &:before {
    content: $icon-post-bank; 
  }
}
.icon-file {
  &:before {
    content: $icon-file; 
  }
}
.icon-job-descrip {
  &:before {
    content: $icon-job-descrip; 
  }
}
.icon-upcom-vacancies {
  &:before {
    content: $icon-upcom-vacancies; 
  }
}
.icon-advertisement {
  &:before {
    content: $icon-advertisement; 
  }
}
.icon-app-received {
  &:before {
    content: $icon-app-received; 
  }
}
.icon-app-recvd-forcefully {
  &:before {
    content: $icon-app-recvd-forcefully; 
  }
}
.icon-shortlisting {
  &:before {
    content: $icon-shortlisting; 
  }
}
.icon-meeting-schedules {
  &:before {
    content: $icon-meeting-schedules; 
  }
}
.icon-issue-letter {
  &:before {
    content: $icon-issue-letter; 
  }
}
.icon-mom {
  &:before {
    content: $icon-mom; 
  }
}
.icon-user-mgmgnt {
  &:before {
    content: $icon-user-mgmgnt; 
  }
}
.icon-notice-board {
  &:before {
    content: $icon-notice-board; 
  }
}
.icon-acr {
  &:before {
    content: $icon-acr; 
  }
}
.icon-board-reports {
  &:before {
    content: $icon-board-reports; 
  }
}
.icon-attendence {
  &:before {
    content: $icon-attendence; 
  }
}
.icon-profile {
  &:before {
    content: $icon-profile; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-settings {
  &:before {
    content: $icon-settings; 
  }
}
.icon-close {
  &:before {
    content: $icon-close; 
  }
}
.icon-tick {
  &:before {
    content: $icon-tick; 
  }
}
.icon-alert {
  &:before {
    content: $icon-alert; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-arrow-up {
  &:before {
    content: $icon-arrow-up; 
  }
}
.icon-right-arrow {
  &:before {
    content: $icon-right-arrow; 
  }
}
.icon-plain-arrow {
  &:before {
    content: $icon-plain-arrow; 
  }
}
.icon-email {
  &:before {
    content: $icon-email; 
  }
}
.icon-print {
  &:before {
    content: $icon-print; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-edit {
  &:before {
    content: $icon-edit; 
  }
}
.icon-refresh {
  &:before {
    content: $icon-refresh; 
  }
}
.icon-copy {
  &:before {
    content: $icon-copy; 
  }
}
.icon-paswrd-visible {
  &:before {
    content: $icon-paswrd-visible; 
  }
}
.icon-paswrd-hidden {
  &:before {
    content: $icon-paswrd-hidden; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-upload {
  &:before {
    content: $icon-upload; 
  }
}
.icon-delete {
  &:before {
    content: $icon-delete; 
  }
}
.icon-take-action {
  &:before {
    content: $icon-take-action; 
  }
}
.icon-faq {
  &:before {
    content: $icon-faq; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-help-support {
  &:before {
    content: $icon-help-support; 
  }
}
.icon-menu {
  &:before {
    content: $icon-menu; 
  }
}
.icon-add {
  &:before {
    content: $icon-add; 
  }
}
.icon-letter_issue {
  &:before {
    content: $icon-letter_issue;     
  }
}
.icon-master1 {
  &:before {
    content: $icon-master1;     
  }
}
.icon-notice-board1 {
  &:before {
    content: $icon-notice-board1;     
  }
}
.icon-report {
  &:before {
    content: $icon-report;     
  }
}
.icon-updt_annual_report {
  &:before {
    content: $icon-updt_annual_report;     
  }
}
.icon-updt-cpse-prof {
  &:before {
    content: $icon-updt-cpse-prof;     
  }
}
.icon-user_mgmt {
  &:before {
    content: $icon-user_mgmt;     
  }
}
.icon-vacancy {
  &:before {
    content: $icon-vacancy;     
  }
}
.icon-acr_approval {
  &:before {
    content: $icon-acr_approval;     
  }
}
.icon-acr-progress {
  &:before {
    content: $icon-acr-progress;     
  }
}
.icon-advrtmnt {
  &:before {
    content: $icon-advrtmnt;     
  }
}
.icon-board_reports {
  &:before {
    content: $icon-board_reports;     
  }
}
.icon-file-plane {
  &:before {
    content: $icon-file-plane;     
  }
}
.icon-edit-doc {
  &:before {
    content: $icon-edit-doc; 
  }
}
