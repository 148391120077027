.radio-btn-wrap{
    background-color:#FFFFFF ;
    border: 1px solid #D8D8D8 ;
    border-radius: 0.4rem;
    color: inherit;
    padding: 1.8rem 2.4rem;
    .form-check {
        display: inline-flex;
        flex-direction: column-reverse;
        align-items: center;
        &:first-of-type {
            padding-left: 3.8rem;
        }
        &-input[type=radio] {
            margin-left: 0;
            width: 2.3rem;
            height: 2.3rem;
            border: 2px solid #A3A3A3;
        }
    }
        
}