.screening-application {
    &_candidate-summary {

        .icon-download,
        .icon-print {
            font-size: 2.2rem;
        }

        .card-body {
            &.candidate-details {
                border-radius: 0;

                p {
                    margin-top: 5px;
                    font-size: 1.6rem;
                }
            }

            &+.card-header {
                border-radius: 0 !important;

            }
        }

        p {

            strong,
            b {
                font-size: 1.6rem;
                font-family: $primary-font-medium;
                font-weight: normal;
            }


            &.b2 {
                .icon-info {
                    font-size: 2rem;
                }
            }
        }

      
    }

    &_input-form {
        .accordion {
            .accordion-body {
                padding: 0;

                .accordion-sub-item {
                    padding-top: 3rem;
                    padding-bottom: 3rem;
                    border-bottom: 1px solid #ccc;

                    &+.card-header {
                        border-radius: 0 !important;

                        &+.accordion-sub-item {
                            border-bottom-left-radius: 0.8rem !important;
                            border-bottom-right-radius: 0.8rem !important;
                        }
                    }

                    &:nth-last-child(1),
                    &:nth-last-child(3) {
                        border-bottom: 0 none;
                    }
                }




            }

        }

        .form-section-title {
            padding: 0 0 2.4rem 0;
        }

        .password {
            &--visibile {
                span {
                    font-size: 2.5rem;

                }
            }
        }

        .actions {
            .btn {
                font-size: 4.5rem;

                &:disabled {
                    color: $gray-silver-color;
                }
            }

            .icon-tick {
                color: #008F5D;
            }

            .icon-close {
                color: #EA3728;
            }
        }
       

        .badge-status {
            &__current {
                border-radius: 2rem;
                background: #fcdcad;
                color: #c3862e;
                display: inline-block;
                padding: 0.7rem 1.5rem;
                text-transform: uppercase;
                font-size: 1.2rem;
            }
        }

        button.badge-status-updated {
            padding: 0.7rem 2.4rem;
        }

        p.badge-status-updated {
            .badge-status {
                &__current {
                    padding: 0.2rem 1.5rem;
                }
            }
        }

        .accordion-button:not(.collapsed) {
            &.badge-status-updated {
                padding: 1.5rem 2.4rem;

                .badge-status {
                    &__current {
                        display: none;
                    }
                }
            }
        }



    }

}

.legal-details {
    .question-list {
        position: relative;

        ul {
            li {
                margin-top: 1rem;

                &:first-of-type {
                    margin: 0;

                    &::before {
                        display: none;
                    }

                }

                &::before {
                    background: #ccc;
                    content: '';
                    width: 100%;
                    height: 1px;
                    position: absolute;
                    right: 0;
                    left: 0;
                    transform: translateY(-13px);
                }

            }

        }

    }

    &-item {
        padding: 3rem 4rem 3rem 4rem;
        border-bottom: 1px solid #ccc;

        &:first-child {
            padding: 0 4rem 3rem 4rem;
        }

        &:last-child {
            border-bottom: 0 none;
        }

    }

}

.user-agreement {
    .form-section-title {
        font-size: 1.6rem;
    }

    .declaration-input {
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .input-group {
            width: 32.0rem;
            margin: 0 2rem 1rem 2rem;
            @include breakpoint(max-1024){
                width: 30%;
            }
        }

    }

}

.unordered-list {
    list-style-type: none;
    counter-reset: section;
    padding-left: 0;

    li {
        margin-top: 3rem;
        position: relative;

        .list-section-title {
            padding-left: 2.5rem;
        }

        &::before {
            content: " ";
            @extend .b7;
            counter-increment: section;
            content: counters(section, ".") ")";
            position: absolute;
        }

        &:first-of-type {
            margin-top: 0;
        }

    }

}

.experience-details {
    .candidate-history-questionnaire {
        section {
            &.card-body {
                border-radius: 0.8rem 0.8rem 0 0 !important;

                p.b2 {
                    span {
                        display: block;
                    }
                }

                .b2 {
                    color: $dark-gray;
                }

                &:first-of-type {
                    border-radius: 0 !important;
                }

                &+.card-header {
                    border-radius: 0 !important;

                    &+.accordion-sub-item {
                        border-bottom-left-radius: 0.8rem;
                        border-bottom-right-radius: 0.8rem;
                        border-bottom: 0 none;
                    }
                }

                .form-floating {
                    textarea {
                        &.form-control {
                            height: 20rem;
                        }
                    }
                }

            }

        }


        >section {
            margin-top: 2rem;

            &:first-of-type {
                margin-top: 0;
            }

        }

        .radio-btn-options {
            .form-check-label {
                margin-right: 2rem;
            }
            &>.form-check{
                @include breakpoint(max-1280){
                    padding-left: 0;
                    margin-right: 1.5px;
                }
            }
        }

    }

    .accordion-collapse {

        .accordion-body {
            background: transparent;

            .card-body {
                &:first-of-type {
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                }
            }

            &>.accordion-sub-item {
                background: transparent;
                padding: 0;
            }
        }

    }
}
