.alert {
    padding: 2rem;
    border-radius: 0.4rem;

    svg {
        margin-right: 1.2rem !important;
    }

    p {
        margin-bottom: 0;
    }

    &-success {
        background-color: $white-color;
        border-color: $alert-success-color;
        color: $alert-success-color;
    }

    &-danger {
        background-color: $white-color;
        border-color: $alert-danger-color;
        color: $alert-danger-color;
    }

    &-warning {
        background-color: $white-color;
        border-color: $alert-warning-color;
        color: $alert-warning-color;
    }

    &-primary {
        background-color: $white-color;
        border-color: $primary-color;
        color: $primary-color;
    }

    &-fill {
        background-color: $alert-success-color;
        color: $white-color;
    }

}

.alertPopup{
    display: none;
    &::before{
        width:100vw;
        height:100vh;
        position: fixed;
        top:0;
        left:0;
        content: '';
        opacity:0.1;
    }
    &.active{
        display: block;
        position: fixed;
        right:1.5rem;
        left: 1.5rem;
        bottom:1.5rem;
        width:calc(100% - 20rem);
        margin: auto;
        z-index:1000;
    }
}
main,
.sidebar{
    &.blur{
        filter: blur(2px);
    }
}
body{
    &.hidden{
        overflow-y: hidden;
    }
}

.faqs{
    margin: 0 -24px;
    ul{
        list-style: none;
        padding: 0;
        li{
            padding:24px;
            border-bottom:0.1rem solid $border-color;
            p{
                margin-top:1rem;
            }
            &:last-child{
                border: none;
            }
        }
    }
}

.icon-upload,
.icon-download{
    font-size: 2.2rem !important;
}
.icon-alert{
    font-size: 2rem;
}