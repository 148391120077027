.nav-pills {
    background-color: $light-gray-2;
    border: 1px solid $light-gray;
    border-radius: 4px;
    padding: 4px;

    .nav-item {
        .nav-link {
            font-size: 1.6rem;
            color: $gray-dark-color;
            font-family: $primary-font-semibold;
            padding: 10px;
            border: 1px solid transparent;
            border-radius: 4px;

            &.active {
                background-color: $white-color;
                border-color: $primary-color;
            }

        }

    }

}
