
.main-container {
    .main-container-inner {
        .job-description-wrap{
            .select2-container{
                max-width: 100% !important;
            }
            .badge-box{
                display: none;
                margin-right: 2.5rem;
            }
            .filter-icon{
                .pb-filter{
                    width: inherit;
                    height: inherit;
                    &__cta-icon{
                        &.icon-filter{
                            font-size: 3rem;
                        }
                    }
                    &__header{
                        &-icon{
                            &.icon-filter{
                                font-size: 2.3rem;
                            }
                        }
                    }
                }
            }
            .table-style-1{
                .table{
                    tbody{
                        td{
                            .b7{
                                font-size: 1.4rem;
                            }
                        }
                    }
                }
            }
            .card{
                .card-header{
                    &.round-zero{
                        border-radius: 0 !important;
                    }
                }
                .card-body{
                    .add-link{
                        display: inline-flex;
                        align-items: center;
                        .icon-add{
                            font-size: 2.4rem;
                        }
                    }
                    
                    
                }
            }  
        }
    }
}