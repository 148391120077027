.short-list-candidates {
	.table-style-1 {
		&.no-border {
			table {
				thead {
					.sub-heading {
						th {
							border-bottom: none;
							font-size: 1.4rem;
						}

						th:not(:last-child) {
							border-right: none;
						}

					}

				}

				tbody {
					.select-dp {
						padding: 1.4rem;

						.single-select {
							.select2-container {
								width: 100% !important;
							}

							.select2-selection {
								&__rendered {
									padding: .8rem 3.5rem .8rem 1rem !important;
									text-align: left;
								}

								&__arrow {
									top: .7rem !important;
								}

							}

						}

					}

					tr {
						td:not(:last-child) {
							border-right: none;
						}

					}

				}

			}

		}

		.b4 {
			font-family: $primary-font-medium;
		}

		.form-check {
			align-items: center;

			&-input {
				margin-right: 1rem;
			}

			&-label {
				font-size: 1.4rem;
				font-family: $primary-font-medium;
			}

		}

		.time-date {
			padding: 0;
			max-width: 250px;

			.btn-link {
				display: flex;
				align-items: center;
				justify-content: center;
				color: $dark-gray;
				text-decoration: none;
				padding: 0;
				gap: 8px;

				i {
					color: $primary-color;
					font-size: 2.2rem;
				}

				span {
					display: flex;
					align-items: center;
					font-size: 1.6rem;
				}
			}
		}

	}

}
