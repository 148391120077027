.single-select {
	.select2-selection {
		border: 0.1rem solid $border-color;
		height: auto;

		&__rendered {
			font-size: 1.6rem;
			padding: 1.5rem 3.5rem 1.5rem 1.5rem !important;
			line-height: 25px !important;
			font-family: $primary-font-medium;
		}

		&__arrow {
			top: 15px !important;
			right: 15px !important;

			b {
				border: solid $primary-color !important;
				border-width: 0 2px 2px 0 !important;
				display: inline-block;
				top: 45% !important;
				padding: 4px;
				transform: rotate(45deg) translateY(-50%);
				left: 0 !important;
				transition: .3s;
			}

		}

	}

	.select2-container--open {
		.select2-selection {
			&__arrow {
				b {
					transform: rotate(-135deg) translateY(-50%);
					left: 8px !important;
				}

			}

			&__rendered {
				color: $primary-color;
			}

		}

	}

}

.basic-single {
	border: 0.1rem solid $border-color;

	.select2-results__option {
		padding: 1.3rem 1.5rem;
		font-size: 1.6rem;
		color: $gray-silver-color;

		&--highlighted {
			background-color: $white-color !important;
			color: $gray-silver-color !important;
			font-family: $primary-font-semibold;
		}

		&--selected {
			font-family: $primary-font-semibold;
			background-color: $white-color !important;
		}

	}

}

.form-floating {
	&.with-dropdown {
		label {
			transform: scale(.85) translateY(-.5rem) translateX(.15rem);
			z-index: 1;
		}

		.single-select {
			.select2-selection {
				&__rendered {
					height: 6.6rem;
					padding-top: 2.8rem !important;
				}

				&__arrow {
					top: 2rem !important;
				}

			}

		}

	}

}