.btn {
    box-shadow: none !important;
    text-transform: uppercase;
    border-radius: 0.8rem;
    font-size: 1.8rem;
    font-family: $primary-font-semibold;
    padding: 1.6rem 2rem;
    transition: .4s ease-in-out;
    min-width: 24.5rem;
    & + .btn {
        margin-left: 1rem;
    }
    &.btn-sm {
        padding: 0.6rem 1rem;
        font-size: 1.6rem;
        border-radius: 0.4rem;
        min-width: 14rem;
    }
    &.btn-with-icon {
        text-transform: capitalize;
        display: inline-flex;
        align-items: center;
        gap:0.5rem;
        justify-content: center;
        span[class*="icon-"]{
            font-size: 2rem;
        }
    }
    &.btn-with-arrow {
        display: inline-flex;
        justify-content: space-between;
        align-items: center;
        padding: 1.6rem 2rem;

        span + span {
            transform: rotate(180deg);
        }
    }

    &:disabled {
        cursor: not-allowed;
    }

    &.btn-primary {
        background-color: $primary-color;
        border: 0.1rem solid $primary-color;
        color: $white-color;

        &:hover {
            background-color: $white-color;
            color: $primary-color;
        }

        &:focus,
        &:active {
            background-color: $btn-active-color;
            border-color: $btn-active-color;
            color: $white-color;
        }

        &:disabled {
            background-color: $btn-disabled-color;
            border-color: $btn-disabled-color;
            color: $gray-silver-color;

        }
    }

    &.btn-secondary {
        background-color: $white-color;
        border: 0.1rem solid $primary-color;
        color: $primary-color;

        &:hover {
            background-color: $secondary-hover-color;
            color: $primary-color;
        }

        &:focus,
        &:active {
            background-color: $secondary-active-color;
            border-color: $btn-active-color;
            color: $primary-color;
        }

        &:disabled {
            background-color: $btn-disabled-color;
            border-color: $btn-disabled-color;
            color: $gray-silver-color;
        }
    }


    &.cta-btn {
        padding: 0.5rem;
        color: $primary-color;
        font-family: $primary-font-semibold;
        font-size: 1.8rem;
        border: none;
        min-width: inherit;
        text-transform: capitalize;

        &:hover {
            color: $cta-hover;
        }

        &:focus,
        &:active {
            color: $cta-active;
        }

        &:disabled {
            color: $gray-silver-color;
        }

        span {
            + span {
                // transform: rotate(180deg);
                display: inline-block;
            }
        }
    }
}
.form-action{
    margin-top:5rem;
    text-align: center;
}
.btn-column{
    display: inline-flex;
    flex-direction: column;
    max-width:inherit;
}
 .btn-w-auto {
     min-width: auto;

 }