.badge{
    &.bg-warning{
        background-color: $badge-bg-light-pink !important;
        color: $badge-color;
        padding: 0.6rem 1rem;
        .icon-close{
            font-size: 2.2rem;
        }
    }
    &-title{
        white-space: break-spaces;
        width: 12rem; 
        font-size: 1rem;
    }
}