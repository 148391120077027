/* Font */
$primary-font-regular: 'WorkSans-Regular', sans-serif;
$primary-font-light: 'WorkSans-Light', sans-serif;
$primary-font-medium: 'WorkSans-Medium', sans-serif;
$primary-font-semibold: 'WorkSans-Semibold', sans-serif;
$primary-font-bold: 'WorkSans-Bold', sans-serif;

/* Color List  */
$primary-color: #003b64;
$primary:$primary-color;
$danger-color: #e14d2a;
$warning-color: #fd841f;
$success-color: #097990;

$alert-info-color: #3892f3;
$alert-success-color: #008f5d;
$alert-warning-color: #f68524;
$alert-danger-color: #ea3829;

$secondary-info-color: #286db2;
$secondary-warning-color: #f0cc54;
$secondary-danger-color: #f0b154;
$secondary-success-color: #7183c6;
$secondary-active-color: #98b0c0;
$secondary-hover-color: #648aaf;

$gray-dark-color: #1a1a1a;
$gray-dust-color: #525252;
$gray-platinum-color: #e9e9e9;
$gray-silver-color: #a3a3a3;
$gray-smoke-color: #f6f6f6;
$light-gray: #d8d8d8;
$light-gray-2: #ededed;
$medium-gray: #707070;
$dark-gray: #25243b;
$light-green: #cde4e8;

$light-success-color: #5bc1d6;
$white-color: #ffffff;
$btn-active-color: #31577c;
$btn-disabled-color: #f2f2f2;

$border-color: #d8d8d8;
$cta-hover: #6baebc;
$cta-active: #076073;

$badge-bg-light-pink:#F3EDD8;
$badge-color:#A5851A;

$range-color:#163A61;
$range-bg-color:#DEDEDE;

$border-left-success:#62E0B4;
$border-left-warning:#FFCA9D;
$border-left-danger:#FFB1AA;
/* Path */

$assets-path: '../images';

// Icomoon Icons
$icomoon-font-family: 'icomoon' !default;
$icomoon-font-path: '../fonts' !default;

$icon-acr: "\e900";
$icon-acr_approval: "\e901";
$icon-acr-progress: "\e902";
$icon-add: "\e903";
$icon-advertisement: "\e904";
$icon-advrtmnt: "\e905";
$icon-alert: "\e906";
$icon-app-received: "\e907";
$icon-app-recvd-forcefully: "\e908";
$icon-arrow-up: "\e909";
$icon-attendence: "\e90a";
$icon-board_reports: "\e90b";
$icon-board-reports: "\e90c";
$icon-bold: "\e90d";
$icon-bullet: "\e90e";
$icon-calendar: "\e90f";
$icon-clock: "\e910";
$icon-close: "\e911";
$icon-copy: "\e912";
$icon-dashboard: "\e913";
$icon-delete: "\e914";
$icon-download: "\e915";
$icon-edit: "\e916";
$icon-edit-doc: "\e917";
$icon-email: "\e918";
$icon-faq: "\e919";
$icon-file: "\e91a";
$icon-file-plane: "\e91b";
$icon-filter: "\e91c";
$icon-forward: "\e91d";
$icon-forward-to-review: "\e91e";
$icon-help-support: "\e91f";
$icon-important: "\e920";
$icon-info: "\e921";
$icon-issue-letter: "\e922";
$icon-italics: "\e923";
$icon-job-descrip: "\e924";
$icon-left-alignment: "\e925";
$icon-left-hand: "\e926";
$icon-letter_issue: "\e927";
$icon-master: "\e928";
$icon-master1: "\e929";
$icon-meeting-schedules: "\e92a";
$icon-menu: "\e92b";
$icon-mom: "\e92c";
$icon-notice-board: "\e92d";
$icon-notice-board1: "\e92e";
$icon-notification: "\e92f";
$icon-paswrd-hidden: "\e930";
$icon-paswrd-visible: "\e931";
$icon-plain-arrow: "\e932";
$icon-post-bank: "\e933";
$icon-print: "\e934";
$icon-profile: "\e935";
$icon-re-distribute: "\e936";
$icon-refresh: "\e937";
$icon-reply: "\e938";
$icon-report: "\e939";
$icon-reports: "\e93a";
$icon-right-alignment: "\e93b";
$icon-right-arrow: "\e93c";
$icon-save: "\e93d";
$icon-search: "\e93e";
$icon-send-call-letters: "\e93f";
$icon-settings: "\e940";
$icon-shortlisting: "\e941";
$icon-take-action: "\e942";
$icon-tick: "\e943";
$icon-underline: "\e944";
$icon-upcom-vacancies: "\e945";
$icon-updt_annual_report: "\e946";
$icon-updt-cpse-prof: "\e947";
$icon-upload: "\e948";
$icon-user_mgmt: "\e949";
$icon-user-mgmgnt: "\e94a";
$icon-vacancy: "\e94b";
$icon-view-application: "\e94c";
$icon-view-call-letters: "\e94d";