.sidebar {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 24rem;
  z-index: 1;
  padding: 2.8rem 0;
  background: $success-color;
  transition: left 0.3s ease 0s;

  .closebtn {
    display: none;
  }

  @include breakpoint("max-1024") {
    left: -100%;
  }

  @include breakpoint("max-1024") {
    .closebtn {
      border: none;
      margin-left: auto;
      display: inline-block;
      font-size: 2.2rem;
      position: absolute;
      right: 1.5rem;
      color: $white-color;
    }

  }

  .logo {
    margin-bottom: 2rem;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }

  .nav-menu {
    .navbar-nav {
      .nav-item {
        .nav-link {
          color: $white-color;
          font-family: $primary-font-regular;
          font-size: 1.4rem;
          font-weight: normal;
          padding: 1.6rem 2.4rem;
          display: flex;
          align-items: center;
          position: relative;
          overflow: hidden;

          &.active,
          &:hover {
            &:before {
              content: "";
              position: absolute;
              left: -2.4rem;
              background: rgba($light-success-color, 0.4);
              width: calc(100% + 4.8rem);
              right: inherit;
              z-index: 1;
              height: 100%;
              z-index: -1;
            }

          }

          span:first-child {
            margin-right:0.5rem;
            font-size: 2.2rem;
          }

        }

        .dropdown-item {
          padding-left: 5rem;
          white-space: normal;
          &.active,
          &:hover {
            background: transparent;
            &:before {
              content: "";
              position: absolute;
              left: -2.4rem;
              background: rgba($light-success-color, 0.4);
              width: calc(100% + 4.8rem);
              right: inherit;
              z-index: 1;
              height: 100%;
              z-index: -1;
            }

          }
        }

      }

    }

  }

  .dropdown {
    width: 100%;

    &-menu {
      background: none;
      border: 0;
      padding: 0;
      margin: 0;

      .dropdown-item {
        color: $white-color;

        &:hover {
          background: transparent;
        }

      }

    }

    &-item {
      border-bottom: 0.1rem solid $primary-color;

      &:active, &:focus {
        background: transparent;
      }

    }

  }

  .dropdown-toggle {
    min-width: auto;
    color: $white-color;
    text-align: left;
    font-size: 1.4rem;
    font-weight: normal;
    text-transform: capitalize;
    font-family: $primary-font-regular;
    padding: 1.6rem 0;
    border: none;
    border-bottom: 0.1rem solid $primary-color;
    border-radius: 0;
    white-space: normal;

    &::after {
      content: "";
    }

    .icon-plain-arrow {
      transform: rotate(180deg);
      transition: 0.3s;
    }

    &.show {
      .icon-plain-arrow {
        transform: rotate(270deg);
      }

    }

  }

  .navbar {
    // overflow-y: auto;
    align-items: flex-start;
    height: calc(100vh - 12rem);
    overflow-y: auto;
    scrollbar-width: thin;
    overscroll-behavior: contain;

    /* width */
    &::-webkit-scrollbar {
      height: 6px;  /* height of horizontal scrollbar ← You're missing this */
      width: 6px;   /* width of vertical scrollbar */
      border: 1px solid #F5F5F5;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: #d9d9d9;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $primary-color;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $secondary-hover-color;
    }
  }

}

body {
  @include breakpoint("max-1024") {
    .main-container {
      margin-left: 0;

      .inner-header {
        width: 100%;
      }

    }

    &.openSideMenu {
      overflow: hidden;
      .sidebar {
        left: 0;
        width: 100%;
        z-index: 9999;
      }

      .main-container {
        margin-left: 0;
      }

    }

  }

}

