.accordion {
  .accordion-header {
    &.card-header{
      padding: 0;
      border-radius: 0.8rem !important;
    }
  }
  .b1 {
    color: $gray-dark-color;
  }
  .accordion-collapse{
    &.card-body {
        padding: 0;
      }
  }

  &-button {
    border-radius: 0.8rem;
    background-color: rgba($light-green, 0.5);
    color: #25243b;
    font-size: inherit;
    font-weight: inherit;
    line-height: 1.4rem;
    padding: 1.5rem 2.4rem;
    position: relative;
    &:before{
              content: " ";
              border: solid #003b64 !important;
              border-width: 0 2px 2px 0 !important;
              display: inline-block;
              top: 45% !important;
              padding: 4px;
              transform: rotate(45deg) translateY(-50%);
              transition: 0.3s;
              right: 2.5rem;
              position: absolute;
              top: 0;
    }
    &:after{
      display: none;
    }
    &:not(.collapsed){
      background-color: rgba($light-green, 0.5);
      border-bottom-left-radius:0 !important ;
      border-bottom-right-radius:0 !important ;
      color: initial;
      &:before {
          content: " ";
          transform: rotate(226deg) translateY(-50%);
          right: 1.8rem;
        }
    }
    &:focus{
      border: 0 none;
      box-shadow: none;
    }
  }

  &-body {
    // background: #f0f7f8;
    border-bottom-left-radius:0.8rem ;
    border-bottom-right-radius:0.8rem ;
    color: $gray-dark-color;


  }

  &-item {
    border: 0 none;
    margin-bottom: 1.5rem;

    &:first-of-type {
      border-top-left-radius: 0.8rem;
      border-top-right-radius: 0.8rem;

      .accordion-button {
        border-top-left-radius: 0.8rem;
        border-top-right-radius: 0.8rem;
      }
    }

    &:last-of-type {
      border-bottom-left-radius: 0.8rem;
      border-bottom-right-radius: 0.8rem;

      .accordion-button {
        &.collapsed {
          border-bottom-left-radius: 0.8rem;
          border-bottom-right-radius: 0.8rem;

        }
      }
    }
  }

  &-sub-item {
    background: #f0f7f8;
    color: $gray-dark-color;
    padding: 1.7rem 2.4rem;

    &:last-child {
      border-bottom-left-radius: 0.8rem !important;
      border-bottom-right-radius: 0.8rem !important;
    }
  }
}
