.card {
    border:none;
    border-radius:0.8rem;
    // background-color: rgba($light-green, 0.3);

    &-header {
        background-color: rgba($light-green, 0.5);
        color: $dark-gray;
        font-weight: normal;
        padding: 1.7rem 2.4rem;
        border-radius: 0.8rem 0.8rem 0 0 !important;
        border:none;

        &+.card-body{
            border-radius:0 0 0.8rem 0.8rem !important;
            &:not(:nth-last-of-type(1)){
                border-radius: 0 !important;
            }
        }

        p{
            margin-bottom:0;
        }
    }

    &-body {
        background-color: rgba($light-green, 0.3);
        border: none !important;
        color: $gray-dark-color;
        padding:2rem 2.5rem;
        border-radius:0.8rem;
    
        + .card-body {
            border-top-left-radius: 0;
            border-top-right-radius: 0;
        }

        .icon-download {
            font-size: 2.5rem;
        }

        &.basic-info {
            p {
                line-height: 1.5;
            }

        }
        a{
            text-transform: none;
        }
        .b1{
            color: $gray-dark-color;
        }
       
    }

}
