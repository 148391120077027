.header-section {
  padding: 1.2rem 2.9rem;
  background-color: $primary-color;
  position: fixed;
  top: 0;
  z-index: 9999;
  width: 100%;
  color: $white-color;

  &_left {
    a {
      color: $white-color;
      font-family: $primary-font-semibold;
      font-size: 1.4rem;
      border: none;
    }
  }

  &_end {
    a {
      color: $white-color;
      font-family: $primary-font-regular;
      font-size: 1.4rem;
    }
  }
}

.top-header {
  position: sticky;
  top: 0;
  z-index: 99;

  .inner-header {
    padding: 1.5rem 2.4rem;
    background-color: $primary-color;
    width: 100%;
    color: $white-color;
    height: 6rem;

    &_left {
      display: flex;

      .openbtn {
        display: none;

        @include breakpoint("max-1024") {
          display: inherit;
          width: 3.5rem;
          font-size: 2.5rem;
          background: transparent;
          box-shadow: none;
          border: 0;
          color: $white-color;
        }
      }

      a {
        color: $white-color;
        border: none;
        font-size: inherit;
        display: flex;
        align-items: center;
        span {
          &:nth-child(1) {
            font-size: 3rem;
            transform: rotate(180deg);
          }

          &:nth-child(2) {
            font-size: 2rem;
            font-family: $primary-font-semibold;
          }
        }
      }

      &.before-login {
        a {
          span {
            &:nth-child(1) {
              font-size: 2rem;
            }

            &:nth-child(2) {
              font-size: 1.5rem;
            }
          }
        }
      }
    }

    &_end {
      .dropdown-toggle {
        min-width: auto;
        border: 0;
      }
      .dropdown-menu {
        width: 100%;
        border-radius: 0;
        padding: 0;
        li {
          font-size: 1.2rem;
          .dropdown {
            &-item {
              color: $primary-color;
              padding: 1.5rem 1rem;
              &:active {
                background-color: $primary-color;
                color: $white-color;
              }
            }
          }
        }
      }
      a,
      .profile-name,
      .profile-sub-header {
        color: $white-color;
        font-family: $primary-font-regular;
        font-size: 1.4rem;
        border: none;
        padding: 0;
        text-transform: capitalize;
        span {
          &.header-icon {
            font-size: 3rem;
          }

          &.profile-sub-header {
            font-size: 1rem;
          }
        }
      }
      .profile-sub-header {
        font-size: 1rem;
      }
      .profile-pic {
        img {
          width: 3.2rem;
          height: 3.2rem;
          border-radius: 50%;
        }
      }
    }
  }
}

.breadcrumb {
  &-item {
    font-size: 2rem;
    font-family: $primary-font-semibold;
    a {
      color: $white-color;
      padding-bottom: 0;
    }
    &.active {
      color: $white-color;
    }
  }
}
.breadcrumb-item + .breadcrumb-item::before {
  color: $white-color;
  content: var(--bs-breadcrumb-divider, ">")
    /* rtl: var(--bs-breadcrumb-divider, ">") */;
}

.profile-name {
  white-space: nowrap;
}

.breadcrumb {
  margin-bottom: 0;
  display: flex;
  align-items: center;
}
