p,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

h1,
.h1 {
    font-size: 4.8rem;
    line-height: 6.4rem;
    font-family: $primary-font-semibold;
    letter-spacing: -.02rem;
}

.h_1_5 {
    font-size: 3.6rem;
    line-height: 4.4rem;
    font-family: $primary-font-regular;
    letter-spacing: -.02rem;
}

h2,
.h2 {
    font-size: 2.6rem;
    line-height: 3.6rem;
    font-family: $primary-font-regular;
    letter-spacing: -.02rem;
}

h3,
.h3 {
    font-size: 2.6rem;
    line-height: 3.6rem;
    font-family: $primary-font-semibold;
    letter-spacing: -.02rem;
}

h4,
.h4 {
    font-size: 2.6rem;
    line-height: 3.6rem;
    font-family: $primary-font-bold;
    letter-spacing: -.02rem;
    color: rgba($gray-dark-color, 0.32);
}

.b1 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: $primary-font-semibold;
    letter-spacing: -.02rem;
}

.b2 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: $primary-font-regular;
    letter-spacing: -.02rem;
}

.b3 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $primary-font-regular;
    letter-spacing: -.02rem;
}

.b4 {
    font-size: 1.4rem;
    line-height: 2rem;
    font-family: $primary-font-semibold;
    letter-spacing: -.02rem;
}

.b5 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: $primary-font-regular;
    letter-spacing: -.02rem;
}

.b6 {
    font-size: 1.2rem;
    line-height: 1.8rem;
    font-family: $primary-font-semibold;
    letter-spacing: -.02rem;
}
.b7 {
    font-size: 1.6rem;
    line-height: 2.4rem;
    font-family: $primary-font-medium;
    letter-spacing: -.02rem;
}
a {
    color: $primary-color;
    font-family: $primary-font-semibold;
    font-size: 1.6rem;
    text-decoration: none;
    padding-bottom: 0.3rem;
    border-bottom: 0.1rem solid $primary-color;
    text-transform: capitalize;
    &:visited {
        color: $primary-color;
    }

    &:hover {
        color: $cta-hover;
    }

    &:focus,
    &:active {
        color: $cta-active;
    }
}

.text-disabled {
    color: $gray-silver-color;
}

.text-enable {
    color: $gray-dust-color;
}

.text-info {
    color: $alert-info-color;
}

.text-success {
    color: $alert-success-color;
}

.text-warning {
    color: $alert-warning-color;
}

.text-danger {
    color: $alert-danger-color;
}

.text-primary {
    color: $primary-color !important;
}

p,
ul,
li{
    @extend .b3;
}
b,strong{
    @extend .b4;
}
label{
    @extend .b5;
}

.text-normal {
    text-transform: none;
}