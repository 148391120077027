// .candidate-header{
//     font-size: 1.8rem;
// }

.candidate-title-text{
    font-size: 1rem;
}

.main-container {
    .main-container-inner {
        .best-candidates-wrap{

            .filter-icon{
                .pb-filter{
                    width: inherit;
                    height: inherit;
                    &__cta-icon{
                        &.icon-filter{
                            font-size: 3rem;
                        }
                    }
                    &__header{
                        &-icon{
                            &.icon-filter{
                                font-size: 2.3rem;
                            }
                        }
                    }
                }
            }
            .otp{
                max-width: 50rem;
                margin: auto;
            } 
            .badge-box{
                display: none;
                margin-right: 2.5rem;
                // margin-left:auto;
            } 
            .table-style-1{
                .sub-heading{
                    th{
                        font-size: 1.4rem;
                    }
                    &.equal-table-cell{
                        th{
                            width: 33.33%;
                        }
                    }
                }
                tbody{
                    td{
                        font-size: 1.4rem;
                        font-family: $primary-font-medium;
                        &.action{
                            padding: 1.4rem;
                            white-space: nowrap;
                        }
                    }
                    .badge{
                        border-radius: 1.8rem;
                        font-size: 1.4rem;
                        padding: 1rem 3.3rem;
                        &.bg-primary{
                            background-color: $alert-success-color !important;
                        }
                        &.bg-danger{
                            background-color: $alert-danger-color !important;
                        }
                    }
                    .download-link{
                        font-size: 1.4rem;
                        display: inline-flex;
                        align-items: center;
                        gap: 0.2rem;
                        .icon-upload{
                            font-size: 2rem;
                            transform: rotate(180deg);
                        }
                    }
                    // Write in Table Section If required
                    // .icon-edit{
                    //     font-size: 2rem;
                    // }
                    // .icon-add{
                    //     font-size: 2rem;
                    // }
                    // .icon-app-received{
                    //     font-size: 2rem;
                    // }
                }
            }
            .card{
                .card-header{
                    .card-title{
                        font-size: 1.8rem;
                        line-height: 2.4rem;
                        font-family: $primary-font-semibold;
                    }
                    // .pdf-action-btn{
                    //     .btn-with-icon{
                    //         display: inline-flex;
                    //         align-items: center;
                    //         gap: 0.5rem;
                    //         min-width: fit-content;
                    //         .icon-print,.icon-download{
                    //             font-size: 2rem;
                    //         }
                    //     }
                    // }
                }
                .card-body{
                    .select2-search__field{
                        height: 3rem;
                    }
                    .multiselect-wrap{
                        .select2-container--default {
                            .select2-selection {
                                &--multiple {
                                    .select2-selection__choice {
                                        margin-right: 1.2rem;
                                    }
                                }
                            }
                        }
                    }
                    strong{
                        font-size: 1.6rem;
                        color: $dark-gray;
                    }
                    .b2{
                        font-family: $primary-font-medium;
                    }
                    .best-candidates-card-padding{
                        padding: 1.2rem 2.5rem;
                    }
                    .title-subtext{
                        font-size: 1.6rem;
                    }
                    .candidate-name-text{
                        font-size: 2.4rem;
                    }
                    .blur-content{
                        filter: blur(4px);
                    }
                }
            }
            .send-mom-btn{
                display: inline-block;
                max-width: 24.6rem;
                text-transform: unset;
            }      
        }
    }
}