// Date picker plugin
.datepicker {
    font-family: $primary-font-medium;
    padding: 0;
    &-dropdown {
        font-size: 1.4rem;
    }
    .table-condensed > tbody > tr > td,
    .table-condensed > tbody > tr > th,
    .table-condensed > tfoot > tr > td,
    .table-condensed > tfoot > tr > th,
    .table-condensed > thead > tr > td,
    .table-condensed > thead > tr > th {
        padding: 1rem;
    }
    .table-condensed > thead > tr > th.dow {
        background-color: $light-gray-2;
        border-radius: 0;
        font-weight: normal;
        text-transform: uppercase;
    }
    .day {
        border-radius: 50%;
    }
    td,
    th{
        width:4rem;
        height:4rem;
    }
    table{
        tr{
            td{
                &.active{
                    background-color: $primary-color;
                }
                &.today{
                    background-color: $success-color;
                    background-image: linear-gradient(to bottom,$success-color,$success-color);
                    color: $white-color;
                    &:hover{
                        background-color: $success-color;
                        background-image: linear-gradient(to bottom,$success-color,$success-color);
                        color: $white-color;
                        &:hover {
                            color: $white-color; 
                        }
                    }
                }
            }
        } 
    }
    thead{
        tr:nth-child(2){
            th{
                padding-bottom:1.5rem;
            }
        }
    }
}

.datepicker table tr td.active.active, 
.datepicker table tr td.active.disabled, 
.datepicker table tr td.active.disabled.active, 
.datepicker table tr td.active.disabled.disabled, 
.datepicker table tr td.active.disabled:active, 
.datepicker table tr td.active.disabled:hover, 
.datepicker table tr td.active.disabled:hover.active, 
.datepicker table tr td.active.disabled:hover.disabled, 
.datepicker table tr td.active.disabled:hover:active, 
.datepicker table tr td.active.disabled:hover:hover, 
.datepicker table tr td.active.disabled:hover[disabled], 
.datepicker table tr td.active.disabled[disabled], 
.datepicker table tr td.active:active, 
.datepicker table tr td.active:hover, 
.datepicker table tr td.active:hover.active, 
.datepicker table tr td.active:hover.disabled, 
.datepicker table tr td.active:hover:active, 
.datepicker table tr td.active:hover:hover, 
.datepicker table tr td.active:hover[disabled], 
.datepicker table tr td.active[disabled]{
    background: $secondary-info-color;
}

.datepicker .datepicker-switch, 
.datepicker .next, 
.datepicker .prev, 
.datepicker tfoot tr th,
.datepicker .datepicker-switch:hover, 
.datepicker .next:hover, 
.datepicker .prev:hover, 
.datepicker tfoot tr th:hover{
    background-color: transparent;
    color: $gray-dark-color;
}

.datepicker .next, 
.datepicker .prev{
    font-size: 2.5rem;
}


// Date
.pb-date {
    position: relative;
    &__icon {
        position: absolute;
        right: 1rem;
        top: 50%;
        z-index: -1;
        font-size: 4rem;
        transform: translateY(-50%);
        &::after {
            content: '';
            position: absolute;
            top: 50%;
            left: -0.75rem;
            width: 1px;
            height: 60%;
            transform: translateY(-50%);
            background-color: $border-color;
        }
    }
    &__input {
        background: transparent;
        &:focus {
            background: transparent;
        }
    }
}
