.file-upload-wrapper {
  .form-upload {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1.5em;
    border: 1px solid $border-color;
    border-radius: 0.4rem;
    background-color: $white-color;

    label {
      border: none;
      padding-left: 0;
      font-size: 2rem;
      position: relative;
      cursor: pointer;
      color: $primary-color;
      // transform: rotate(180deg);

      .file-upload {
        display: none;
      }

    }

    .filename {
      font-size: 1.6rem;
      margin-bottom: 0;
      font-family: $primary-font-medium;
      color: $gray-dark-color;
    }

  }

  &.input-group {
    .form-floating {
      > label {
        transform: scale(.85) translateY(-.5rem) translateX(.15rem);
        z-index: 1;
      }

      .form-upload {
        height: 6.6rem;

        label {
          padding-right: 0;
        }

        .filename {
          margin-top: 1.6rem;
        }

      }

    }

  }

}
