.main-container {
    .main-container-inner {
        .live-vacancies-wrap {
            .nodal-officers-table{
                &.table-style-1{
                    table{
                        thead{
                          .sub-heading{
                            th{
                              white-space: normal !important;
                              &:nth-child(1){
                                width: 16% !important;
                              }
                              &:nth-child(2), &:nth-child(3){
                                width: 18% !important;
                              }
                              &:last-child{
                                width: 55% !important;
                              }
                            }
                          }
                        }
                        // tbody{
                        //     tr{
                        //         td{
                        //             .susps-checkbox{
                        //                 .box{
                        //                     width: calc(100% / 2);
                        //                 }
                        //             }
                        //         }
                        //     }
                        // }
                    }
                }
            }
            .btn-with-same-height{
                padding-top: 1.1rem;
                padding-bottom: 1.1rem;
                &.icon-close{
                    color: #EA3728;
                    border: 0;
                    font-size: 5rem;
                    background: none;
                }
            }
        }
    }
}  

.datepicker{
    .datepicker-years{
        .table-condensed{
            tbody{
                tr{
                    td{
                        span.year.active {
                            background: #286db2;
                        }
                    }
                }
            }
        }
    }
}