.icons-page-bg {
    background: $light-gray;
}

.main-container {
    margin-left: 240px;

    .main-container-inner {
        padding: 24px;

        .live-vacancies-wrap {
            .tab-content {
                margin-top: 3rem;
            }

            .search-box {
                min-width: 40%;
            }

        }

    }

}

.screening-application_candidate-summary{
    .btn-print-button{
        min-width: fit-content;
        padding: 1.1rem;
    }
}

