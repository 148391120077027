.pb-filter {
    position: relative;
    width: 20px;
    height: 20px;
    z-index: 2;
    &__cta {
        cursor: pointer;
        &-icon {
            font-size: 2rem;
        }
    }
    &__box {
        border: 1px solid $border-color;
        background: $white-color;
        border-radius: 0.8rem;
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        width: 320px;
    }
    &__header {
        border-bottom: 1px solid $border-color;
        padding: 0.5rem 1.5rem;
        position: relative;
        &-title {
            color: $primary-color;
        }
    }
    &__clear-btn {
        position: absolute;
        right: 1.5rem;
        top: 50%;
        transform: translateY(-50%);
        cursor: pointer;
        color: $gray-silver-color;
    }
    &__section {
        border-bottom: 1px solid $border-color;
        &-icon {
            position: absolute;
            right: 1.8rem;
            top: 50%;
            transform: translateY(-50%) rotate(-45deg);
            transition: transform 0.3s ease;
            height: 10px;
            width: 10px;
            border-left: 1px solid #000;
            border-bottom: 1px solid #000;
            margin-top: -2px;
        }
        &-title {
            cursor: pointer;
            text-transform: uppercase;
            color: $primary-color;
            &.collapsed{
                .pb-filter__section-icon{
                    transform: translateZ(0) rotate(135deg);
                    transition: transform 0.3s ease;
                }
            }
        }
        &-header {
            position: relative;
            padding: 1rem 1.5rem;
        }
        &-data {
            padding: 0 1.5rem 1.5rem 1.5rem;
            .form-check {
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .additional-item {
                display: none;
            }
        }
        &-more-btn {
            text-align: right;
            span {
                cursor: pointer;
            }
            .less {
                display: none;
            }
            &--less {
                .all {
                    display: none;
                }
                .less {
                    display: block;
                }
            }
        }
    }
    &__action {
        padding: 1rem 0.5rem;
        text-align: center;
    }
}
