.main-container {
    .main-container-inner {
        .job-description-wrap{
          .card{
            .card-header{
                &.rounded-zero{
                    border-radius: 0 !important;
                }
            }
            .card-body{
                .work-exp{
                    &-padding{
                        padding: 1.8rem 2.5rem;
                    }
                }
                .btn-with-add-more{
                    padding: 1.9rem 1rem;
                    margin-top: 0.2rem;
                    display: flex;
                    .icon-add{
                        font-size: 2.4rem;
                    }
                }
            }
            .btn-with-icon-delete{
                border-color:$alert-danger-color;
                color: $gray-dark-color;
                font-family: $primary-font-semibold;
                padding: 1.2rem 2.8rem;
                display: flex;
                align-items: center;
                background: $white-color;
                display: flex;
                align-items: center;
                justify-content: center;
                &.btn-with-padding{
                    padding: 1.9rem 1rem;
                    margin-top: 0.2rem;
                }
                &:hover {
                    color: $white-color;
                    background: $alert-danger-color;
                    .icon-delete{
                        color: $white-color;
                    }
                }
        
                &:focus,
                &:active {
                    color:$white-color;
                    background: $alert-danger-color;
                    .icon-delete{
                        color: $white-color;
                        background: $alert-danger-color;
                    }
                }
                .icon-delete{
                    font-size: 2.25rem;
                    color: #EA3728;
                }
            }
          }
        }
    }
}


.weightage-box{
    position: fixed;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 0.8rem;
    background-color: $success-color;
    text-align: center;
    width: 7.5rem;
    padding: 2rem 0.9rem;
    color: $white-color;
    &-text{
        font-size: 1.2rem;
        font-family: $primary-font-medium;
    }
    &-pencentage{
        font-size: 2.7rem;
        line-height: 3.3rem;
        font-family: $primary-font-semibold;
    }
 }
