// Dropdown

.pb-dropdown {
  position: relative;
  // max-width: 240px;
  margin-bottom: 15px;

  &__txt {
    border: 1px solid $border-color;
    background-color: $white-color;
    font-size: 1.6rem;
    padding: 1.5rem;
    border-radius: 0.4rem;
    cursor: pointer;
    font-family: $primary-font-medium;
    color: $gray-dark-color;
  }

  &__arrow {
    position: absolute;
    right: 2.5rem;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
    height: 10px;
    width: 10px;
    border-left: 2px solid $primary-color;
    border-bottom: 2px solid $primary-color;
    margin-top: -2px;
    transition: 0.3s;
  }

  &__content {
    border: 1px solid $border-color;
    background-color: $white-color;
    border-radius: 0.4rem;
    display: none;
    position: absolute;
    top: 54px;
    min-width: 240px;
    z-index: 2;
  }

  &__search {
    border-bottom: 1px solid $border-color;
    padding: 0.5rem 1.5rem;

    input {
      font-size: 1.6rem;
      border: 0;
      width: 100%;
    }
  }

  &__checkbox-list {
    padding: 1.5rem;

    .form-check {
      margin-bottom: 0;

      + .form-check {
        margin-top: 0.5rem;
      }
    }
  }

  &__view {
    border-top: 1px solid $border-color;
    padding: 0.5rem 1.5rem;
    text-align: center;
    background: #f9f9f9;

    a {
      text-decoration: none;
      padding-bottom: 0;
      font-size: 1.6rem;
      border-bottom: 0;
      font-weight: bold;
    }
  }

  &--open {
    .pb-dropdown__arrow {
      top: 55%;
      transform: translateY(-50%) rotate(-225deg);
    }
  }

  &.with-float {
    > label {
      position: absolute;
      color: rgba($gray-dark-color, 0.32);
      font-family: $primary-font-regular;
      font-size: 1.6rem;
      padding: 1.9rem 1.5rem;
      line-height: 1.8rem;
      opacity: 1 !important;
      transform: scale(0.85) translateY(-1rem) translateX(-0.35rem);
      z-index: 1;
    }

    .pb-dropdown__txt {
      padding-top: 2.5rem;
      height: 6.6rem;
    }

    .pb-dropdown__content {
      top: 64px;
    }
  }
}

.dropdown-toggle::after {
  display: none;
}
.input-full-width{
  .select2-container{
   width: 100% !important;

  }
}
// .select2-container .select2-selection--single .select2-selection__rendered
.input-text-wrap{
  .select2-container{
    .select2-selection{
      &--single{
        .select2-selection__rendered{
          white-space: normal;
        }
      }
    }
  }
}
// .select2-selection{
//   &__rendered{
//       white-space: normal;
//   }

// }