input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

.form-control {
  outline: none;
  box-shadow: none;
  border: 0.1rem solid $border-color;
  font-size: 1.6rem;
  font-family: $primary-font-medium;
  padding: 1.5rem;
  margin: 0;
  color: $gray-dark-color;
  width: 100% !important;
  border-radius: 0.4rem;

  &.error {
    border-color: $alert-danger-color;
  }

  &:focus {
    background-color: $white-color;
    border-color: $primary-color;
    box-shadow: none;
  }

  &::placeholder {
    color: rgba($gray-dark-color, 0.32);
    font-family: $primary-font-regular;
  }

  &:disabled {
    background-color: $gray-smoke-color;
    cursor: not-allowed;
  }

  & + p {
    margin-top: 0.5rem;
  }
}

input[type="text"],
input[type="password"] {
  border-radius: 0.4rem !important;
}

textarea {
  font-size: 1.6rem;
  font-family: $primary-font-medium;
  padding: 1.5rem;
}

.form-floating {
  width: 100% !important;
  .form-control {
    border: 0.1rem solid $border-color;
    font-size: 1.6rem;
    font-family: $primary-font-medium;
    padding: 1.8rem 1.5rem;
    margin: 0;
    color: $gray-dark-color;
    border-radius: 0.4rem !important;
    height: calc(6.4rem + 0.2rem);
    &:disabled {
      background-color: $gray-smoke-color;
      cursor: not-allowed;
    }
    &:focus {
      background-color: $white-color;
      border-color: $primary-color;
      box-shadow: none;

      & + label {
        line-height: 1.8rem;
        opacity: 1;
      }
    }

    &:not(:placeholder-shown) {
      padding-top: 2.3rem;
    }
  }

  label {
    color: rgba($gray-dark-color, 0.32);
    font-family: $primary-font-regular;
    font-size: 1.6rem;
    padding: 1.9rem 1.5rem;
    line-height: 1.8rem;
    transform: translateY(0.3rem);
    opacity: 1 !important;
  }

  textarea {
    &.form-control {
      height: calc(10.4rem + 0.2rem);
      padding-top: 2.625rem;
      & + label {
        line-height: 1.8rem;
        transform: translateY(0rem);
      }
      &:focus {
        ~ label {
          line-height: 1rem;
          opacity: 1;
        }
      }

      &:not(:placeholder-shown) {
        padding-top: 2.625rem;

        ~ label {
          line-height: 1rem;
          opacity: 1;
        }
      }
    }
  }
  input[type="date"] {
    padding: 1.2rem 1.5rem;
    &:not(:placeholder-shown) {
      padding-top: 1rem;
    }
  }
}

.otp {
  display: flex;
  flex-direction: column;

  label {
    @extend .b5;
  }

  .otp-container {
    display: flex;
    margin: 0.5rem 0;

    input {
      max-width: 10rem;
      text-align: center;

      & + input {
        margin-left: 1rem;
      }

      &:placeholder {
        font-size: 2rem;
      }
    }
  }
}

.form-check {
  &-input {
    margin-left: 0;
    box-shadow: none !important;
    border: 0.2rem solid $gray-silver-color;

    &:checked[type="radio"] {
      // background-color: $white-color;
      // border: 0.3rem solid $white-color;
      // background-color: $primary-color;
      // background-image: none;
      // outline: 0.2rem solid $primary-color;

      + label {
        color: $primary-color;
      }
    }
  }
}

.input-group {
  flex-direction: column;
  margin-bottom: 1.5rem;

  &.input-icon {
    position: relative;

    .form-control {
      padding-right: 4rem;

      & + span {
        font-size: 3rem;
        position: absolute;
        right: 1rem;
        top: 1.3rem;
        cursor: pointer;
        z-index: 10;
        color: $border-color;

        &.active {
          &::before {
            content: "\e930";
          }
        }
      }

      &:focus,
      &:not(:placeholder-shown) {
        & + span {
          color: $primary-color;
        }
      }
    }

    .form-floating {
      .form-control {
        & + span {
          top: 1.8rem;
        }
      }
    }
  }
}

.form-check {
  padding-left: 2.4rem;
  margin-bottom: 1rem;
  display: flex;

  .form-check-input {
    width: 2.4rem;
    min-width: 2.4rem;
    height: 2.4rem;
    margin-left: -2.4rem;
    margin-right: 1rem;
    border-color: $border-color;
  }

  input[type="checkbox"] {
    &.form-check-input {
      &:focus {
        background-size: 55%;
      }

      &:checked {
        border-color: $primary-color;
        background-color: $white-color;
        background-image: url(#{$assets-path}/checked.svg);
        background-size: 55%;
      }
    }
  }

  label {
    @extend .b2;
  }
}

input[type="date"]:required:invalid::-webkit-datetime-edit {
  color: transparent;
}

input[type="date"]:focus::-webkit-datetime-edit {
  color: black !important;
}

input[type="text"].form-control-bottom-border {
  min-width: 2rem;
  width: 100%;
  max-width: 11rem;
  border: 0;
  border-bottom: 0.1rem solid $border-color;
  border-radius: 0 !important;
  color: #a3a3a3;
  text-align: center;
  &::placeholder {
    color: #a3a3a3;
  }
}

.qtySelector {
  border: 0.1rem solid $border-color;
  border-radius: 0.4rem;
  display: flex;
  align-items: center;
}
.qtySelectorBtn {
  width: 3rem;
  min-width: auto;
  display: inline-block;
  padding: 0;
  &.disable {
    cursor: auto;
    opacity: 0.5;
  }
}
input[type="text"].qtyValue {
  width: 5rem;
  text-align: center;
  font-weight: 600;
  border: 0;
  border-radius: 0 !important;
  border-left: 0.1rem solid $border-color;
  border-right: 0.1rem solid $border-color;
  padding: 1rem 0rem;
  background: #f6f6f6;
  &:active,
  &:focus {
    border: 0;
    outline: 0;
  }
}
:not(.btn-check) + .qtySelectorBtn:active,
:not(.btn-check) + .qtySelectorBtn:focus {
  border: 0;
}

.button-row-container {
  width: 250px;
  height: 50px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 20px auto 0;
  border: 1px solid gray;
  padding: 1em;
}

.button-container {
  display: inline-block;
  margin: 10px 10px;
  cursor: pointer;
  font-weight: 400;
  letter-spacing: 2px;
  height: 45px;
  width: 200px;
  -webkit-perspective: 1000;
  -ms-perspective: 1000;
  perspective: 1000;
}

/* -------------------------------- 
			Switch
-------------------------------- */
.switch-label {
  font-size: 1.4rem;
  margin-right: 1.5rem;
  color: $light-gray;
  white-space: nowrap;
}

.switch-container {
  display: inline-block;
}

/*** Toggle Slide Style ***/

.switch-toggle-slide {
  width: 250px;
  height: 20px;
  border: 1px solid rgba(218, 220, 222, 0.7);
  border-radius: 3px;
  will-change: transform;
  background: rgba(74, 82, 85, 0.8);
  position: relative;

  span.active {
    display: block;
    position: absolute;
    left: 0;
    width: 125px;
    height: 20px;
    background: rgba(218, 220, 222, 0.7);
    z-index: -1;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  .switch-slide {
    width: 125px;
    height: 20px;
    display: inline-block;
    float: left;
    vertical-align: middle;
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    background: transparent;
    color: white;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;

    &#slide-on {
      border-top-left-radius: 2px;
      border-bottom-left-radius: 2px;
    }

    &#slide-off {
      border-top-right-radius: 2px;
      border-bottom-right-radius: 2px;
    }

    &.active-switch {
      color: $dark-gray;
    }
  }
}

/*** Slim Switch Style ***/

input.slim {
  max-height: 0;
  max-width: 0;
  opacity: 0;

  & + label {
    display: block;
    position: relative;
    text-indent: -5000px;
    height: 10px;
    width: 40px;
    border-radius: 15px;
  }

  & + label:before {
    content: "";
    position: absolute;
    display: block;
    height: 10px;
    width: 10px;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: transparent;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  & + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 20px;
    width: 20px;
    top: -5px;
    left: 0px;
    border-radius: 15px;
    background: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  &:checked + label:before {
    width: 40px;
  }

  &:checked + label:after {
    left: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  }
}

input#slim-blue {
  & + label {
    background: rgba(60, 187, 247, 0.7);
  }

  & + label:after {
    background: blue;
  }

  &:checked + label:before {
    background: rgba(60, 187, 247, 0.7);
  }
}

input#slim-red {
  & + label {
    background: rgba(231, 76, 60, 0.7);
  }

  & + label:after {
    background: red;
  }

  &:checked + label:before {
    background: rgba(231, 76, 60, 0.7);
  }
}

input#slim-green {
  & + label {
    background: rgba(19, 191, 17, 0.7);
  }

  & + label:after {
    background: $alert-success-color;
  }

  &:checked + label:before {
    background: rgba(19, 191, 17, 0.7);
  }
}

input#slim-black {
  & + label {
    background: rgba(0, 0, 0, 0.7);
  }

  & + label:after {
    background: black;
  }

  &:checked + label:before {
    background: rgba(0, 0, 0, 0.7);
  }
}

/*** iOS Style ***/

input#ios {
  max-height: 0;
  max-width: 0;
  opacity: 0;
  position: absolute;
  left: -9999px;
  pointer-event: none;

  & + label {
    display: block;
    position: relative;
    text-indent: -5000px;
    height: 2.5rem;
    width: 50px;
    border-radius: 15px;
    background: $white-color;
    cursor: pointer;
  }

  & + label:before {
    content: "";
    position: absolute;
    display: block;
    height: 2.5rem;
    width: 2.5rem;
    top: 0;
    left: 0;
    border-radius: 15px;
    background: transparent;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  & + label:after {
    content: "";
    position: absolute;
    display: block;
    height: 2.2rem;
    width: 2.2rem;
    top: 0.15rem;
    left: 0.15rem;
    border-radius: 15px;
    background: $gray-silver-color;
    -moz-transition: 0.2s ease-in-out;
    -webkit-transition: 0.2s ease-in-out;
    transition: 0.2s ease-in-out;
  }

  &:checked + label:before {
    width: 50px;
    background: $alert-success-color;
  }

  &:checked + label:after {
    left: 2.8rem;
    background: $white-color;
  }
}

// radio btn


.form-check {
   

    &-input[type=radio] {
      width: 2.3rem;
      height: 2.3rem;
      border: 2px solid #A3A3A3;

      &:checked {
        background-color: transparent;
        background-image: none;
        position: relative;
        z-index: 1;
        border: 0 none;

        &::before {
          content: "";
          display: inline-block;
          height: 2.5rem;
          width: 2.5rem;
          border: 3px solid white;
          -webkit-border-radius: 100%;
          border-radius: 100%;
          left: -2px;
          top: -2px;
          position: absolute;
        }

        &::after {
          content: "";
          background: $primary-color;
          display: block;
          position: absolute;
          top:-3px;
          left: -3px;
          z-index: -1;
          height: 27px;
          width: 27px;
          border-radius: 100%;
        }

      }
    }
  }
.interview-can{
  .form-control{
    font-family: $primary-font-regular;
    
  }
}