.main-container {
    .main-container-inner {
        .live-vacancies-wrap {
            .badge-filter{
                margin: 0 1.8rem 0 auto;
                .filter-icon{
                    margin-bottom:1.6rem;
                    .pb-filter{
                        &__cta-icon{
                            &.icon-filter{
                                font-size: 3rem;
                            }
                        }
                        &__header{
                            &-icon{
                                &.icon-filter{
                                    font-size: 2.5rem;
                                }
                            }
                        }
                    }    
                }
                .badge-box{
                    display: none;
                    margin-left:auto;
                }
            }
            .shortlisted-table{
                &-right{
                    i{
                        font-size: 2.2rem;
                        &.icon-filter{
                            font-size: 2.4rem;
                        }
                    }
                }
                .sorting-preferences{
                    .pb-filter{
                        &__box{
                            width: 35.5rem;
                        }
                        &__header{
                            &-icon{
                                &.icon-filter{
                                    font-size: 2.6rem;
                                }
                            }
                        }
                        &.sp-popup-right{
                            .pb-filter__box{
                                right: 0;
                                left: initial;
                            }
                        }
                        &__range{
                            padding: 3.2rem 1.5rem;
                            &-digit{
                                span{
                                    font-size: 1.1rem;
                                    line-height: 1.3rem;
                                    font-family: $primary-font-bold;
                                }
                            }
                            &-text{
                                span{
                                    font-size: 1.4rem;
                                    line-height: 1.6rem;
                                    font-family: $primary-font-medium;
                                }
                            }
                        }
                        &__checkbox{
                            .form-check-label{
                                font-size: 1.4rem ;
                                line-height: 1.6rem;
                                font-family: $primary-font-medium;
                                font-weight: 500;
                            }
                        }
                    }
                }
            }

            .recommended{
                width: 29.4rem;
                margin-bottom: 4rem;
                &-line{
                    width: 100%;
                    background: transparent linear-gradient(270deg, #FFB1AA 0%, #FFCA9D 49%, #62E0B4 100%) 0% 0% no-repeat padding-box;
                    height: 1.5rem;
                    margin-bottom: 0.8rem;
                }
                span{
                    font-size: 1.3rem;
                    color:$dark-gray;
                }
            }
        }

    }

}

.forward-review{
    &.show{    
     z-index: 1051;
    }
    &-preview{
        padding: 2.8rem 4rem;
        &-title{
             font-size: 1.8rem;
             color: $dark-gray;
             margin-bottom: 2rem;
        }
    }
    .send-for-review{
        padding: 0rem 4rem;
        &-title{
            font-size: 1.8rem;
            color: $primary-color;
            margin-bottom: 2rem;
        }
    }
 }

// Dropdown Componet style here
.ac-dropdown{
    &.dropdown{
        width: fit-content;
        .dropdown-toggle{
            border: none;
            background: transparent;
            color: $medium-gray;
            .dropdown-arrow{
                background: $primary;
                color: $white-color;
                padding: 4px;
                border-radius: 0.5rem;
                font-size: 1.5rem;
                span{
                    display: inline-block;
                    transform: rotate(270deg);
                    transition: all 0.3s ease-in-out;
                }
            }
            &.show{
                .dropdown-arrow{
                    span{
                       transform: rotate(90deg);
                    }
                } 
            }
            &::after {
                display: none;
            }
        }
        .dropdown-menu{
            z-index: 1;
            li{
               .dropdown-item{
                font-size: 1.4rem;
                line-height: 2rem;
                font-family: $primary-font-regular;
                letter-spacing: -0.02rem;
                &:active {
                    background-color: $primary-color;
                    color: $white-color;
                }
               }
            }
        }
    }
}






