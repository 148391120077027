.list {
  &__item {
    padding-left: 1.5rem;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      top: 0.8rem;
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      background: $medium-gray;
    }
  }
}

.divider {
  border-bottom: 1px solid $border-color;
}

.declaration-field {
  width: 32rem;
  display: inline-flex;
}

.btn-print {
  .icon-print {
    font-size: 2.4rem;
  }
}
.format-list {
  padding: 0;
  margin: 0;
  background: $white-color;
  border-radius: 0.8rem;
  &-items {
    list-style: none;
    display: flex;
    border-right: 1px solid $gray-silver-color;
    padding: 0 1rem;
    &:last-child {
      border-right: 0;
    }
  }
  .btn {
    min-width: auto;
    padding: 0;
    margin: 0;
    color: $primary-color;
    border: 0;
    padding: 1rem;
    &:active {
      border: 0;
    }
    span {
      display: block;
    }
  }
  .icon-bullet,
  .icon-reply,
  .icon-forward {
    font-size: 3rem;
  }
  .icon-left-alignment,
  .icon-right-alignment,
  .icon-bold,
  .icon-italics,
  .icon-underline {
    font-size: 2.5rem;
  }
}

.card-header-overflow {
  margin-left: -2.4rem;
  margin-right: -2.4rem;
}
