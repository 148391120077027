.stepwizard {
    display: table;
    width: 100%;
    position: relative;

    .stepwizard-row {
        display: block;
        margin: auto;
        text-align: center;

        .stepwizard-step {
            display: inline-block;
            text-align: left;
            position: relative;
            width: calc(100%/3 - 4rem);
            margin-left: 0;

            &:not(:last-child):after {
                top: 1.4rem;
                right: inherit;
                left: 3rem;
                bottom: 0;
                position: absolute;
                content: " ";
                width: calc(100% - 2.5rem);
                height: 0.1rem;
                background-color: $gray-silver-color;
            }

            &:last-child {
                width: 10rem;
            }

            .check {
                display: inline-block;
                transform: rotate(45deg);
                height: 12px;
                width: 6px;
                margin-top: -2px;
                border-bottom: .2rem solid $white-color;
                border-right: .2rem solid $white-color;
            }

            p {
                margin-top: 1rem;
                font-size: 1.4rem;
                color: $dark-gray;
                font-family: $primary-font-semibold;
            }

            button[disabled] {
                opacity: 1 !important;
                filter: alpha(opacity=100) !important;
            }

            .btn-default {
                background: $light-green;
                color: $white-color;
                border: 0.1rem solid $border-color;

                &.btn-circle {
                    min-width: 3rem;
                    width: 3rem;
                    height: 3rem;
                    text-align: center;
                    padding: 0.4rem;
                    font-size: 1.3rem;
                    border-radius: 50%;
                    color: $white-color;
                    font-family: $primary-font-semibold;
                    position: relative;
                }

                &:hover {
                    background: $success-color;
                    border: 1px solid $light-success-color;
                }

            }

            &.passed {
                .btn-default {
                    background: $success-color;
                    border: 0.1rem solid $light-success-color;

                    span {
                        font-size: 3rem;
                        color: $white-color;
                    }

                    &:after {
                        background-color: $gray-silver-color;
                    }

                }

                &:after {
                    background: $primary-color;
                }

            }

        }

    }

}
