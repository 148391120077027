.multiselect-wrap {
    margin-bottom: 1.5rem;

    .select2-container--default {
        .select2-selection {
            &--multiple {
                background: $white-color;
                border: 1px solid #D8D8D8;
                padding: 2rem 1.5rem;

                .select2-selection__choice {
                    background-color: #D7E6F5;
                    border: 0 none;
                    border-radius: 1.8rem;
                    color: #286DB2;
                    font-weight: 600;
                    padding: 0.7rem 4rem 0.7rem 1.5rem;
                    position: relative;
                    margin-top: 15px;

                    .select2-selection__choice__remove {
                        border-right: 0 none;
                        border-radius: 50%;
                        background-color: #003B64;
                        color: #fff;
                        right: 0.6rem;
                        left: unset;
                        top: 50%;
                        transform: translateY(-50%);
                        width: 1.5rem;
                        height: 1.5rem;
                        padding: 0;

                        span {
                            color: #D7E6F5;
                            display: block;
                            padding: 0;
                            width: 1.5rem;
                            height: 1.5rem;
                            font-size: 1.1rem;
                            line-height: 1.5rem;
                        }
                    }
                }
            }

        }


    }

    .select2-container--focus {
        .select2-selection {
            &--multiple {
                background-color: $white-color;
                padding: 2rem 1.5rem;
                font-size: 1.6rem;
            }
        }
    }

}

// .select2-container--default .select2-results__option--highlighted.select2-results__option--selectable
.select2-container--default {

    // .select2-dropdown{
    >:not(.basic-single) {
        .select2-results {
            &__option {
                &--highlighted {
                    &.select2-results {
                        &__option {
                            &--selectable {
                                background: initial;
                                color: initial;


                            }
                        }
                    }

                }

                &--selected {
                    background-color: transparent;
                    

                    &:before {
                        background-image: url(../images/checked.svg);
                        color: #003b64;
                        /* background-color: #ffffff; */
                        border: 2px solid $primary-color;
                        display: inline-block;
                        padding-left: 3px;
                        background-repeat: no-repeat;
                        background-position: center;
                        font-size: 15px;
                        background-size: 15px 20px;

                    }
                }
                


                &:before {
                    content: "";
                    display: inline-block;
                    position: relative;
                    height: 2.4rem;
                    width: 2.4rem;
                    border: 0.2rem solid $primary-color;
                    border-radius: 0.2rem;
                    background-color: #fff;
                    margin-right: 20px;
                    vertical-align: middle;
                }
            }

        }
    }

    // } 

}


.select2-container--default .select2-selection--multiple {
    margin-bottom: 10px;
}

.select2-container--default.select2-container--open.select2-container--below .select2-selection--multiple {
    border-radius: 4px;
}

.select2-container--default .select2-selection--multiple {
    border-width: 2px;
}


.select2-selection .select2-selection--multiple:after {
    content: 'hhghgh';
}