.modal {
  &-backdrop {
    backdrop-filter: blur(2px);
    background-color: transparent;

    &.show {
      opacity: 1 !important;
    }
  }

  &-header {
    border-bottom: none;
    padding: 2rem 3rem 1rem 3rem;

    .close-link {
      display: inline-flex;
      align-items: center;
      position: relative;
      font-size: 1.8rem;
      .icon-close {
        font-size: 3rem;
        position: absolute;
        left: -3.5rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }

    .btn {
      min-width: auto;
      line-height: 1;

      [class^="icon-"],
      [class*=" icon-"] {
        font-size: 2.2rem;
      }
    }
  }

  &-content {
    // box-shadow: 0px 0px 8px 3px rgba($primary-color, 0.1);
    box-shadow: 0px 3px 10px #003b6450;
    border: none;
  }

  &-body {
    padding: 2rem 3rem;
  }

  &-footer {
    border-top: none;
    padding: 1.5rem 2rem 2rem 2rem;
  }

  &-title {
    font-size: 2.6rem;
    font-family: $primary-font-semibold;
    color: $primary-color;
  }
}

.viewDocModal {
  .modal {
    &-header {
      padding: 2rem 6rem 1rem 6rem;
    }
  }
}

.sendMailModal {
	.modal {
		&-header {
			padding: 4rem 2rem 1rem 8rem;

			.close-link {
				top: -3rem;
			}
		}

		&-body {
			padding: 2rem 8rem 1rem 8rem;
		}

	}
}

.editDate-time{
  .date-time-padding{
    max-width: 61rem;
    margin: auto;
    .set-time{
      border: 0.1rem solid $range-bg-color;
      color: $dark-gray;
      padding: 1.9rem;
      text-align: center;
      border-radius: 0.4rem;
      .icon-clock{
        font-size: 2.8rem;
        color: $primary;
      }
    }
    .start-time-box{
      border-radius: 0.4rem;
      padding: 0.8rem 0.6rem 1.3rem 0.6rem;
      &-header{
        font-size: 1.2rem;
        line-height: 2.2rem;
        font-family: $primary-font-medium;
        &.mb12{
          margin-bottom: 1.2rem;
        }
      }
  
      .set-hours,
        .set-mins{
          border:0.1rem solid #d8d8d8;
          /* padding:0.5rem; */
          border-radius:0.4rem;
          font-size: 1.4rem;
          display: flex;
          align-items: center;
        }
        .modal-btn-plus-minus{
          width: 3.5rem;
          min-width: auto;
          display: inline-block;
          padding: 0;
          height: 100%;
          border: none;
          background: white;
          outline: none;
        }
        .modal-input-number{
          width: 6rem;
          text-align: center;
          font-weight: 600;
          border: 0;
          -webkit-border-radius: 0 !important;
          border-radius: 0 !important;
          border-left: 0.1rem solid #d8d8d8;
          border-right: 0.1rem solid #d8d8d8;
          padding: 1.8rem 0rem;
          background: #f6f6f6;
        }
    }
  }
}