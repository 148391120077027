.login-container{
    min-height: 100vh;
    &__logo{
        background-color: $primary-color;
        img{
            max-width:60%;
        }
    }
    &__form{
        max-width:50rem;
        margin: auto;
        padding:2rem;
        h3{
            margin-bottom:4rem;
        }
        .btn + .btn{
            margin:1.5rem 0 0 0;
        }
        .hint-text{
            display: flex;
            span{
                font-size:2rem;
                color: $gray-silver-color;
            }
        }
    }
}
.new-user-container{
    min-height: calc(100vh - 6rem);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    max-width:30%;
    margin: auto;
    .select-user{
        display: flex;
        width: 100%;
        margin-bottom:2rem;
        .form-check{
            margin-right:3rem;
        }
    }
}
.candidate-user{
    max-width:85%;
    margin: auto;
    padding:4rem 0;
    display: flex;
    justify-content: space-between;
    .left-register,
    .right-register{
        width:45%;
    }
    .right-register{
        .form-check{
            display: flex;
            .form-check-label{
                width:calc(100% - 4.6rem)
            }
        }
    }
}